import $ from "jquery";
import "magnific-popup";
import "slick-carousel";
import { ShareHandler } from "./share";
export default class UIBindings {

    formSubmitButton() {
        $(document).on('submit', 'form', function () {
            let submitText = $(this).find('[data-submit-text]');
            let submittingText = $(this).find('[data-submitting-text]');
            let btn = submitText.closest('button');
            
            submittingText.removeClass('d-none');
            submitText.addClass('d-none');
            btn.prop('disabled', true);
        });
    }
    magnificPopup () {
        let image = $('[mfp-type="image"]');
        let gallery = $('[mfp-type="gallery"]');

        if (image.length > 0) {
            image.magnificPopup({type: "image"});
        }
        if (gallery.length > 0) {
            gallery.magnificPopup({
                type: 'image',
                closeOnContentClick: false,
                closeBtnInside: true,
                mainClass: 'mfp-with-zoom mfp-img-mobile',
                gallery: {
                    enabled: true,
                    navigateByImgClick: true,
                    tCounter: '%curr% of %total%',
                    preload: [0, 2] // Will preload 0 - before current, and 1 after the current image
                },
                zoom: {
                    enabled: true,
                    duration: 500 // don't foget to change the duration also in CSS
                }
            });
        }
    }

    ourWork(){

     let hashed =  window.location.hash.substr(1);
   

if(window.location.hash){
  $('html,body').scrollTop(0);

}

$(window).on('load',function() {
    // Animate loader off screen
    $(".se-pre-con").fadeOut("fast");;
  });

  if (hashed.length) {
       console.log('hash',hashed);
       $('#content-sectors').children('div:not(.' + hashed + ')').addClass('d-none');
       $('#content-sectors').children('div.' + hashed).removeClass('d-none');

       $('#sectors .card-img.' + hashed  ).addClass('show-img-mobile').removeClass('hide-img-mobile');
       $('#sectors .card-img:not(.' + hashed + ')' ).addClass('hide-img-mobile').removeClass('show-img-mobile');
   }
   

   $('#sectors div a').click(function() {     

//     var ourClass = $(this).data('sector');
//     $('#sectors div a').removeClass('active');
//     console.log(ourClass);
//     $(this).parent().addClass('active');

//     $('#holder').children('article:not(.' + ourClass + ')').fadeOut('slow');
//     $('#holder').children('article.' + ourClass).fadeIn('slow');

//     $('#content-sectors').children('div:not(.' + ourClass + ')').addClass('d-none');
//     $('#content-sectors').children('div.' + ourClass).removeClass('d-none');

    

//     $('#sectors div.gradient:not(.' + ourClass+ ')').removeClass('active');
//     $('#sectors div.gradient.' + ourClass ).addClass('active');

    $('#sectors .card-img.' + ourClass  ).addClass('show-img-mobile').removeClass('hide-img-mobile');
    $('#sectors .card-img:not(.' + ourClass + ')' ).addClass('hide-img-mobile').removeClass('show-img-mobile');

});


//    if(window.location.hash){
//     // smooth scroll to the anchor id
//     $('html,body').animate({
//         scrollTop:$(window.location.hash).offset().top - 30
//     },1000);
// }


// $('a[href*="#"]')

// .not('[href="#"]')
// .not('[href="#0"]')
// .click(function(event) {

//     if (
//       location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
//       && 
//       location.hostname == this.hostname
//       ) {

//       var target = $(this.hash);
//   target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

//   if (target.length) {

//     event.preventDefault();
//     $('html, body').animate({
//         scrollTop: target.offset().top - 30
//     }, 1000, function() {

//       var $target = $(target);
//       $target.focus();
//       if ($target.is(":focus")) { 
//         return false;
//     } else {
//         $target.attr('tabindex','-1'); 
//         $target.focus();

//     };
// });
// }
// }
// });

}

slickCarourel() {
    let carousel = $('[data-slick]');
    if (carousel.length > 0) {
        carousel.slick();

    }
}

share() {
    const shareHandler = new ShareHandler();
    shareHandler.build();
}

}